@import '~bootstrap/scss/bootstrap-utilities';
@import '../../assets/sass/variables';
@import '../../assets/sass/buttons';

#About {
    background-color: var(--var-color-003);
    background: linear-gradient(180deg, var(--var-color-003-light) 0%, var(--var-color-003) 50%, var(--var-color-003-dark) 100%);
    height: auto;
    min-height: calc(100vh - var(--var-header-height));
    align-items: flex-start;

    .container {
        .row {
            &.about {
                padding: 2rem;
                align-items: center;

                @include media-breakpoint-down(sm) {
                    padding: 0;
                }
            }

            .col {
                &.heading {
                    h2 {
                        color: var(--var-color-white);
                        text-align: right;
                    }
                }

                &.section-subheading {
                    h3 {
                        color: var(--var-color-white);
                    }
                }

                &.image {
                    text-align: center;

                    img {
                        width: 100%;
                        border-radius: 100%;
                        border: 1rem solid var(--var-color-white);
                    }
                }

                &.content {
                    color: var(--var-color-white);

                    .content-wrapper {
                        padding: 2rem;

                        @include media-breakpoint-down(md) {
                            padding: 4rem 2rem 0 2rem;
                            text-align: center;
                        }

                        .subsection {
                            margin-bottom: 1rem;
                        }
                    }
                }

                &.cta {
                    .content-wrapper {
                        color: var(--var-color-white);
                        text-align: center;
                        padding: 4rem 2rem;

                        @include media-breakpoint-down(lg) {
                            padding: 2rem;
                        }

                        @include btn-mf-outline-white($color-003);
                    }
                }
            }
        }
    }
}