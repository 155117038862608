@import '~bootstrap/scss/bootstrap-utilities';
@import '../../assets/sass/variables';
@import '../../assets/sass/buttons';

#CV {
    background-color: var(--var-color-002);
    background: linear-gradient(180deg, var(--var-color-002-light) 0%, var(--var-color-002) 50%, var(--var-color-002-dark) 100%);
    height: auto;
    min-height: calc(100vh - var(--var-header-height));
    align-items: flex-start;
    color: var(--var-color-white);

    .container {
        .row {

            &.employment {
                padding: 1rem;
            }

            .col {
                &.heading {
                    h2 {
                        color: var(--var-color-white);
                        text-align: left;
                    }
                }

                &.section-subheading {
                    h3 {
                        color: var(--var-color-white);
                    }
                }

                &.employer {
                    @include media-breakpoint-down(md) {
                        padding-bottom: 1.2rem;
                    }

                    p {
                        margin-bottom: 0.5rem;
                    }
                }

                &.role {

                }

                &.cta {
                    .content-wrapper {
                        color: var(--var-color-white);
                        text-align: center;
                        padding: 4rem 2rem;

                        @include media-breakpoint-down(lg) {
                            padding: 2rem;
                        }

                        @include btn-mf-white($color-002);
                    }
                }
            }
        }
    }
}