@import '~bootstrap/scss/bootstrap-utilities';

#Work {
    background-color: var(--var-color-004);
    background: linear-gradient(180deg, var(--var-color-004-dark) 0%, var(--var-color-004) 50%, var(--var-color-004-light) 100%);
    height: auto;
    min-height: calc(100vh - var(--var-header-height));
    align-items: flex-start;
    color: var(--var-color-002);

    .container {
        .row {
            .col {
                &.heading {
                    h2 {
                        color: var(--var-color-002);
                        text-align: right;
                    }
                }

                &.section-subheading {
                    h3 {
                        color: var(--var-color-002);
                    }
                }

                &.carousel {
                    .swiper-slide {
                        @include media-breakpoint-down(lg) {
                            height: unset;
                        }

                        .item {
                            height: 100%;
                            min-height: 300px;

                            @include media-breakpoint-down(lg) {
                                flex-direction: column;
                                align-items: center;
                                justify-content: space-between;
                            }

                            .col {
                                padding: 2rem 0;

                                @include media-breakpoint-down(lg) {
                                    padding: 1rem;
                                }

                                &.content {
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;

                                    .content-wrap {
                                        display: block;
                                        text-align: right;
                                        padding: 3rem;

                                        @include media-breakpoint-down(lg) {
                                            text-align: center;
                                            padding: 1rem;
                                        }
                                    }
                                }

                                img {
                                    width: 100%;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}