.btn {
    border-radius: 6px;
    border-width: 3px;
    font-weight: 900;
    margin: 2rem;
    text-align: center;
    white-space: nowrap;
    line-height: 2rem;
    position: relative;
    overflow: hidden;
    z-index: 1;
    transition: 0.5s;

    span {
        position: absolute;
        width: 25%;
        height: 100%;
        transform: translateY(150%);
        border-radius: 50%;
        left: calc((var(--n) - 1) * 25%);
        transition: 0.5s;
        transition-delay: calc((var(--n) - 1) * 0.1s);
        z-index: -1;
    }

    span:nth-child(1) {
        --n: 1;
    }
    
    span:nth-child(2) {
        --n: 2;
    }
    
    span:nth-child(3) {
        --n: 3;
    }
    
    span:nth-child(4) {
        --n: 4;
    }

    @media (hover: hover) {
        &:hover,
        &:focus {
            span {
                transform: translateY(0) scale(2);
            }
        }
    }

    &.btn-mf-primary {
        background-color: var(--var-color-001);
        color: var(--var-color-white);
        border-color: var(--var-color-001);

        span {
            background-color: var(--var-color-white);
        }

        @media (hover: hover) {
            &:hover,
            &:focus {
                color: var(--var-color-001);
            }
        }
    }

    &.btn-mf-secondary {
        background-color: var(--var-color-002);
        color: var(--var-color-white);
        border-color: var(--var-color-002);

        span {
            background-color: var(--var-color-white);
        }

        @media (hover: hover) {
            &:hover,
            &:focus {
                color: var(--var-color-002);
            }
        }
    }

    &.btn-mf-outline-primary {
        background-color: transparent;
        color: var(--var-color-001);
        border-color: var(--var-color-001);

        span {
            background-color: var(--var-color-001);
        }

        @media (hover: hover) {
            &:hover,
            &:focus {
                color: var(--var-color-white);
            }
        }
    }

    &.btn-mf-outline-secondary {
        background-color: transparent;
        color: var(--var-color-002);
        border-color: var(--var-color-002);

        span {
            background-color: var(--var-color-002);
        }

        @media (hover: hover) {
            &:hover,
            &:focus {
                color: var(--var-color-white);
            }
        }
    }
}

@mixin btn-mf-white($color) {
    .btn-mf-white {
        background-color: var(--var-color-white);
        color: $color;
        border-color: var(--var-color-white);

        span {
            background-color: $color;
        }

        @media (hover: hover) {
            &:hover,
            &:focus {
                color: var(--var-color-white);
            }
        }
    }
}

@mixin btn-mf-outline-white($color) {
    .btn-mf-outline-white {
        background-color: transparent;
        color: var(--var-color-white);
        border-color: var(--var-color-white);

        span {
            background-color: var(--var-color-white);
        }

        @media (hover: hover) {
            &:hover,
            &:focus {
                color: $color;
            }
        }
    }
}

@mixin btn-mf-custom($color1, $color2) {
    .btn-mf-custom {
        background-color: $color2;
        color: $color1;
        border-color: $color2;

        span {
            background-color: $color1;
        }

        @media (hover: hover) {
            &:hover,
            &:focus {
                color: $color2;
            }
        }
    }
}

@mixin btn-mf-outline-custom($color1, $color2) {
    .btn-mf-outline-custom {
        background-color: transparent;
        color: $color2;
        border-color: $color2;

        span {
            background-color: $color2;
        }

        @media (hover: hover) {
            &:hover,
            &:focus {
                color: $color1;
            }
        }
    }
}