@import '~bootstrap/scss/bootstrap-utilities';

#Contact {
    background-color: var(--var-color-005);
    background: linear-gradient(180deg, var(--var-color-005-dark) 0%, var(--var-color-005) 50%, var(--var-color-005-light) 100%);
    height: auto;
    min-height: calc(100vh - var(--var-header-height));
    align-items: flex-start;

    h2 {
        color: var(--var-color-002);
        text-align: right;
    }

    .container {
        .row {
            justify-content: center;

            .col {

                &.section-subheading {
                    h3, h4 {
                        color: var(--var-color-002);
                    }
                }

                &.form {
                    color: var(--var-color-002);
                    text-align: center;

                    .input-wrapper {
                        margin-bottom: 1rem;

                        &.form-floating {
                            label {
                                font-weight: 600;
                            }
                        }

                        input:not(.btn):not([type="radio"]),
                        textarea {
                            width: 100%;
                            font-weight: 600;

                            &:focus {
                                border-color: var(--var-color-002);
                                box-shadow: 0 0 0 0.25rem var(--var-color-002);
                            }
                        }

                        textarea {
                            min-height: 200px;
                        }
                    }

                    .subheading {
                        padding: 1rem;
                    }

                    .radio-buttons {
                        .input-wrapper {
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: center;

                            .radio-button {
                                padding: 0;

                                input {
                                    border-color: var(--var-color-002);
                                    float: none;

                                    &:checked {
                                        background-color: var(--var-color-002);
                                        border-color: var(--var-color-002);
                                    }

                                    &:focus {
                                        border-color: var(--var-color-002);
                                        outline: 0;
                                        box-shadow: none;
                                    }
                                }

                                label {
                                    color: var(--var-color-002);
                                    font-weight: 600;
                                    padding-left: .4rem;
                                }
                            }
                        }
                    }

                    .response {
                        .success {
                            display: none;
                            border-bottom: 4px solid var(--var-color-002);
                            width: fit-content;
                            margin: 0 auto;

                            &.show {
                                display: block;
                                transition: .5s ease-in-out;
                            }
                        }

                        .error {
                            display: none;
                            color: var(--var-color-error);
                            border-bottom: 4px solid var(--var-color-error);
                            width: fit-content;
                            margin: 0 auto;

                            &.show {
                                display: block;
                                transition: .5s ease-in-out;
                            }
                        }
                    }
                }
            }
        }
    }
}