$color-white: #FFFFFF;
$color-black: #000000;
$color-error: #DC3545;

$color-001: #E84A5F;
$color-001-dark: darken($color-001, 20%);
$color-001-light: lighten($color-001, 20%);

$color-002: #2A363B;
$color-002-dark: darken($color-002, 20%);
$color-002-light: lighten($color-002, 20%);

$color-003: #FF847C;
$color-003-dark: darken($color-003, 20%);
$color-003-light: lighten($color-003, 20%);

$color-004: #FECEA8;
$color-004-dark: darken($color-004, 20%);
$color-004-light: lighten($color-004, 20%);

$color-005: #99B898;
$color-005-dark: darken($color-005, 20%);
$color-005-light: lighten($color-005, 20%);

$color-linkedin-blue: #0077B5;
$color-linkedin-lightgrey: #F0F2F4;
$color-linkedin-darkgrey: #313335;

:root {
    --var-color-white: #{$color-white};
    --var-color-black: #{$color-black};
    --var-color-error: #{$color-error};
    --var-color-001: #{$color-001};
    --var-color-001-dark: #{$color-001-dark};
    --var-color-001-light: #{$color-001-light};
    --var-color-002: #{$color-002};
    --var-color-002-dark: #{$color-002-dark};
    --var-color-002-light: #{$color-002-light};
    --var-color-003: #{$color-003};
    --var-color-003-dark: #{$color-003-dark};
    --var-color-003-light: #{$color-003-light};
    --var-color-004: #{$color-004};
    --var-color-004-dark: #{$color-004-dark};
    --var-color-004-light: #{$color-004-light};
    --var-color-005: #{$color-005};
    --var-color-005-dark: #{$color-005-dark};
    --var-color-005-light: #{$color-005-light};
    --var-color-linkedin-blue: #{$color-linkedin-blue};
    --var-color-linkedin-lightgrey: #{$color-linkedin-lightgrey};
    --var-color-linkedin-darkgrey: #{$color-linkedin-darkgrey};
    --var-header-height: 60px;
    --var-footer-height: 60px;
    --var-font-001: 'Inter', 'Inter-Regular', sans-serif;
    --var-font-002: 'Inter', 'Inter-Black', sans-serif;
}