html {
    scroll-behavior: smooth;
    scroll-padding-top: var(--var-header-height);

    body {
        position: relative;
        font-family: var(--var-font-001);
        font-size: 16px;

        #root {
            .App {
                .section {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position: relative;
                    padding-top: 4rem;
                    padding-bottom: 4rem;
                    overflow: hidden;

                    h2 {
                        text-transform: uppercase;
                        font-weight: 900;
                        border-bottom: 6px solid;
                    }

                    h3 {
                        font-weight: 700;
                    }

                    .swiper-button-next, .swiper-button-prev {
                        color: var(--var-font-002);
                    }

                    .section-subheading {
                        padding: 4rem 2rem;
                        text-align: center;

                        @include media-breakpoint-down(sm) {
                            padding: 3rem 2rem;
                        }

                        h3 {
                            font-family: var(--var-font-001);
                            font-weight: 900;
                            color: var(--var-color-black);
                            font-size: 4rem;
                            line-height: 4rem;

                            @include media-breakpoint-down(sm) {
                                font-size: 2rem;
                                line-height: 2rem;
                            }
                        }
                    }
                }
            }
        }
    }
}