@import '~bootstrap/scss/bootstrap-utilities';
@import '../../assets/sass/variables';

#Hero {
    background-color: var(--var-color-white);
    height: auto;
    min-height: calc(100vh + var(--var-header-height));
    align-items: center;
    margin-top: -var(--var-header-height);

    .container {
        .row {
            .col {
                text-align: center;

                .heading {
                    font-family: var(--var-font-001);
                    font-weight: 900;
                    color: var(--var-color-black);
                    font-size: 4rem;
                    line-height: 4rem;

                    @include media-breakpoint-down(md) {
                        font-size: 3rem;
                        line-height: 3rem;
                    }

                    @include media-breakpoint-down(sm) {
                        font-size: 2rem;
                        line-height: 2rem;
                        margin-bottom: 1rem;
                    }
                }

                .subheading {
                    font-family: var(--var-font-001);
                    font-weight: 600;
                    color: var(--var-color-001);
                    font-size: 2rem;
                    line-height: 3rem;

                    @include media-breakpoint-down(sm) {
                        font-size: 1.8rem;
                        line-height: 1.8rem;
                    }
                }
            }
        }
    }

    $bar-count: 5;
    $sway-type: "sway-left-to-right", "sway-right-to-left";

    @function random_range($min, $max) {
        $rand: random();
        $random_range: $min + floor($rand * (($max - $min) + 1));
        @return $random_range;
    }

    @function random_animation($type) {
        @return nth($type, random(length($type)));
    }

    .bars {
        display: flex;
        position: absolute;
        top: 0;
        right: 0;
        width: 10vw;
        height: 100%;

        .bar {
            display: block;
            width: var(--bar-width);
            height: 100%;

            &::before {
                position: absolute;
                content: '';
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: rgba($color-001, 0.3);
                border-radius: inherit;
                animation: var(--bar-sway-type) var(--bar-sway-duration) var(--bar-sway-delay) ease-in-out alternate infinite;
            }

            @for $i from 0 through $bar-count {
                &:nth-child(#{$i}) {
                    --bar-sway-duration: #{random_range(4s, 6s)};
                    --bar-sway-delay: #{random_range(0s, 4s)};
                    --bar-sway-type: #{random_animation($sway-type)};
                    --bar-width: #{random_range(1rem, 8rem)};
                }
            }

            &.bar1 {
                width: 2.5rem;
                background-color: var(--var-color-001);
                animation: variable-width var(--bar-sway-duration) ease-in-out alternate infinite;
            }

            &.bar2 {
                width: 3rem;
                background-color: var(--var-color-002);
                animation: variable-width var(--bar-sway-duration) ease-in-out alternate infinite;
            }

            &.bar3 {
                width: 1.5rem;
                background-color: var(--var-color-003);
                animation: variable-width var(--bar-sway-duration) ease-in-out alternate infinite;
            }

            &.bar4 {
                width: 1rem;
                background-color: var(--var-color-004);
                animation: variable-width var(--bar-sway-duration) ease-in-out alternate infinite;
            }

            &.bar5 {
                width: 2rem;
                background-color: var(--var-color-005);
                animation: variable-width var(--bar-sway-duration) ease-in-out alternate infinite;
            }
        }
    }
}

@keyframes variable-width {
    from {
        width: 1rem;
    }

    to {
        width: 5rem;
    }
}

@keyframes sway-left-to-right {
    from {
        transform: translateX(-100%);
    }

    to {
        transform: translateX(100%);
    }
}

@keyframes sway-right-to-left {
    from {
        transform: translateX(100%);
    }

    to {
        transform: translateX(-100%);
    }
}