@import '~bootstrap/scss/bootstrap-utilities';

#Header {
    background-color: rgba(#FFFFFF,0.5);
    height: var(--var-header-height);
    transition: 0.25s ease-out;
    position: fixed;
    left: 0;
    right: 0;

    &.scrolled {
        background-color: var(--var-color-002);

        .navbar-brand {
            color: var(--var-color-white);
        }

        #responsive-navbar-nav {
            .navbar-nav {
                div[data-nav] {
                    .nav-link {
                        color: var(--var-color-white);
                    }
                }
            }
        }
    }

    .navbar-brand {
        position: relative;
        overflow: hidden;
        color: var(--var-color-black);
        font-weight: 900;
        font-size: 1rem;

        span {
            color: var(--var-color-001);
        }
    }

    .navbar-toggler {
        border: 0;

        &:focus {
            box-shadow: none;
        }
    }

    #responsive-navbar-nav {
        @include media-breakpoint-down(lg) {
            position: absolute;
            top: var(--var-header-height);
            width: 100%;
            background-color: var(--var-color-002);
            right: -100vw;
            text-align: center;
            transition: 0.5s ease;

            &.collapse {
                display: block;
                height: 0;
                visibility: hidden;

                .navbar-nav {
                    height: 0;
                }
            }

            &.collapsing {
                overflow: unset;
                visibility: hidden;
            }

            &.show {
                height: calc(100vh - var(--var-header-height));
                padding: 2rem;
                visibility: visible;
                right: 0;

                .navbar-nav {
                    height: 100%;
                }
            }
        }

        .navbar-nav {
            @include media-breakpoint-down(lg) {
                height: 100%;
                align-items: center;
                justify-content: center;
            }

            div[data-nav] {
                @include media-breakpoint-down(lg) {
                    display: contents;
                }

                .nav-link {
                    display: inline-block;
                    position: relative;
                    color: var(--var-color-black);
                    cursor: pointer;
                    font-size: 18px;
                    white-space: nowrap;
                    overflow: hidden;

                    @include media-breakpoint-down(lg) {
                        color: var(--var-color-white);
                        font-size: 2rem;
                        font-weight: 700;
                    }

                    &:before,
                    &:after {
                        position: absolute;
                        width: 100%;
                        height: 1px;
                        top: 100%;
                        left: 0;
                        pointer-events: none;
                    }

                    &:before {
                        content: '';
                    }
                }
            }
        }
    }

    .brand-link__wave,
    .nav-link__wave {
        position: absolute;
        top: 0;
        left: 0;
        pointer-events: none;
        fill: none;
        stroke: transparent;
        top: -3px;
        stroke-width: 5px;
        transition: transform 0.9s;
        transition-timing-function: cubic-bezier(0, 0.25, 0.5, 1);
        transition-delay: 400ms;
    }

    .navbar-brand:hover .brand-link__wave,
    .nav-link.is-active .nav-link__wave {
        stroke: var(--var-color-001);
        transform: translate3d(-66.6%, 0, 0);
    }
}