@import '~bootstrap/scss/bootstrap-utilities';
@import '../../assets/sass/variables';
@import '../../assets/sass/buttons';

#More {
    background-color: var(--var-color-white);
    height: auto;
    min-height: calc(100vh - var(--var-header-height));
    align-items: flex-start;

    h2 {
        color: var(--var-color-001);
        text-align: left;
    }

    .container {
        .row {
            .col {
                h3 {
                    text-align: center;
                }

                &.social {
                    padding-top: 3rem;
                    padding-bottom: 3rem;

                    @include media-breakpoint-down(md) {
                        padding-top: 2rem;
                        padding-bottom: 2rem;
                    }

                    .linkedin-badge {
                        .profile-image {
                            position: relative;
                            height: 260px;

                            .top {
                                background-color: var(--var-color-linkedin-blue);
                                height: 65%;
                            }

                            .bottom {
                                background-color: var(--var-color-linkedin-lightgrey);
                                height: 35%;
                            }

                            img {
                                position: absolute;
                                bottom: 0;
                                left: 50%;
                                transform: translateX(-50%);
                                border: .4rem solid var(--var-color-white);
                                border-radius: 100%;
                                width: 100%;
                                max-width: 200px;
                            }
                        }

                        .profile-info {
                            background-color: var(--var-color-linkedin-lightgrey);
                            color: var(--var-color-linkedin-darkgrey);
                            padding: 1rem;

                            .content-wrapper {
                                max-width: 400px;
                                margin: 0 auto;
                                padding: 1rem;
                                text-align: center;

                                h4 {
                                    font-weight: 800;
                                }

                                @include btn-mf-outline-custom($color-white, $color-linkedin-blue);

                                img {
                                    width: 100%;
                                    max-width: 100px;
                                }
                            }
                        }
                    }
                }

                &.builtwith {
                    padding-top: 3rem;
                    padding-bottom: 3rem;

                    @include media-breakpoint-down(md) {
                        padding-top: 1rem;
                        padding-bottom: 1rem;
                    }

                    p {
                        text-align: center;
                        margin-top: 2rem;
                    }

                    .logos {
                        padding: 2rem;

                        .logo {
                            display: flex;
                            align-items: center;
                            justify-content: center;

                            img {
                                width: 100%;
                                max-width: 200px;
                                padding: 1rem;
                                transition: .3s ease;

                                @media (hover: hover) {
                                    &:hover {
                                        transform: scale(1.1);
                                        transition: .3s ease;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}