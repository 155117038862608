@import '~bootstrap/scss/bootstrap-utilities';

#Frameworks {
    background-color: var(--var-color-white);
    height: auto;
    min-height: calc(100vh - var(--var-header-height));
    align-items: flex-start;

    h2 {
        color: var(--var-color-001);
        text-align: left;
    }

    .container {
        .row {
            align-items: center;

            &.logos {
                @include media-breakpoint-down(sm) {
                    padding: 1rem;
                }
            }

            .col {
                &.logo {
                    padding: 1rem;

                    img {
                        width: 100%;
                        max-width: 300px;
                        transition: .3s ease;

                        @media (hover: hover) {
                            &:hover {
                                transform: scale(1.1);
                                transition: .3s ease;
                            }
                        }
                    }
                }
            }
        }
    }
}